import { mapHelper } from "@/utils/utils.js";

const userType = [
  { value: 1, text: "上报人" },
  { value: 3, text: "监管人" },
  { value: 2, text: "处置人" },
];
const { map: userTypeMap, setOps: userTypeOps } = mapHelper.setMap(userType);
const statusType = [
  { value: "0", text: "待处置" },
  { value: "1", text: "处置中" },
  { value: "2", text: "已完成" },
  { value: "3", text: "已退回" },
];
const { map: statusTypeMap, setOps: statusTypeOps } =
  mapHelper.setMap(statusType);
export { userTypeMap, userType, statusTypeMap, statusType };
