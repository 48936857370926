//查询事件列表
const getOrderInfoListUrl = `/cyproxy/order-info/getOrderInfoList`;
//分页事件
const getOrderInfoPageListUrl = `/cyproxy/order-info/getOrderInfoPageList`;
//查询事件件数
const getOrderInfoCountUrl = `/cyproxy/order-info/getOrderInfoCount`;
// 查询 - 字典表
const getDictOpsUrl = `/cyproxy/dict/getDictListByType`;
// 更新 - 事件 - 状态
const updateOrderWorkStatusUrl = `/cyproxy/order-info/updateOrderStatus`;
// 新字典--事件类别
const getOrderTypeAllListUrl = `/cyproxy/dict/getOrderTypeAllList`;
// 新字典--片区
const getAreaCommunityUnitAllListUrl = `/cyproxy/dict/getAreaCommunityUnitAllList`;
export {
  getAreaCommunityUnitAllListUrl,
  getOrderTypeAllListUrl,
  updateOrderWorkStatusUrl,
  getOrderInfoListUrl,
  getOrderInfoPageListUrl,
  getOrderInfoCountUrl,
  getDictOpsUrl,
};
